export function getThemes() {
	return {
		mocha: {
			base: '#1e1e2e',
			surface0: '#313244',
			text: '#cdd6f4',
			subtext1: '#a6adc8',
			mantle: '#181825',
			mauve: '#cba6f7',
			crust: '#11111b',
			lavender: '#b4befe',
			surface2: '#585b70',
			backgroundimage: "url('https://samwich.dev/assets/snow.gif')",
		},
		latte: {
			base: '#eff1f5',
			surface0: '#ccd0da',
			text: '#4c4f69',
			subtext1: '#5c5f77',
			mantle: '#e6e9ef',
			mauve: '#8839ef',
			crust: '#dce0e8',
			lavender: '#7287fd',
			surface2: '#acb0be',
			backgroundimage: '',
		},
		frappe: {
			base: '#303446',
			surface0: '#414559',
			text: '#c6d0f5',
			subtext1: '#b5bfe2',
			mantle: '#292c3c',
			mauve: '#ca9ee6',
			crust: '#232634',
			lavender: '#babbf1',
			surface2: '#626880',
			backgroundimage: "url('https://samwich.dev/assets/snow.gif')",
		},
		macchiato: {
			base: '#24273a',
			surface0: '#363a4f',
			text: '#cad3f5',
			subtext1: '#b8c0e0',
			mantle: '#1e2030',
			mauve: '#c6a0f6',
			crust: '#181926',
			lavender: '#b7bdf8',
			surface2: '#5b6078',
			backgroundimage: "url('https://samwich.dev/assets/snow.gif')",
		},
	}
}

export function ThemeManager({ theme, children }) {
	const themes = getThemes()

	const themeVariables = Object.keys(themes[theme]).reduce((acc, key) => {
		acc[`--${key}`] = themes[theme][key]
		return acc
	}, {})

	return <div style={themeVariables}>{children}</div>
}
