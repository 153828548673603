import { Hyperlink } from './HyperLink'
import { LinkList } from './LinkList'
import { Blog } from './Blog'

export const ScrollSections = [
	<>
		<h1>Hey!</h1>
		<h2>
			I'm Samwich, but you can call me Sam
			<br />
			I like programming, gaming, and open source software
			<br />
			If you find me in any communities, say hi!!
			<br />
		</h2>
	</>,
	<div>
		<h1>Links</h1>
		<LinkList
			newline={true}
			links={[
				{
					url: 'https://github.com/cheesesamwich',
					name: 'GitHub',
				},
				{
					url: 'https://discord.com/users/976176454511509554',
					name: 'Discord',
				},
			]}
		/>
	</div>,
	/* 
	<div>
		<h1>Blog</h1>
		<Blog />
	</div>,
	*/
	<div>
		<h1>Credits</h1>
		<h2>
			Colour Scheme:{' '}
			<Hyperlink href={'https://github.com/catppuccin/catppuccin'}>
				Catppuccin
			</Hyperlink>
		</h2>
		<h2>
			Icons:{' '}
			<Hyperlink href={'https://fontawesome.com/v6/icons?o=r&s=solid'}>
				Font Awesome Solid
			</Hyperlink>
		</h2>
	</div>,
]
