export function isMobileUserAgent() {
	return [
		'iphone',
		'android',
		'webos',
		'ipad',
		'ipod',
		'blackberry',
		'windows phone',
	].some((keyword) => navigator.userAgent.toLowerCase().includes(keyword))
}
