import { useEffect, useState } from 'react'
import anime from 'animejs/lib/anime.es.js'
import * as React from 'react'

interface ScrollBarProps {
	ScrollSections: any[]
	onIndexChange: (e: number) => void
	texts: string[]
}

export function ScrollBar(props) {
	const { ScrollSections, onIndexChange, texts }: ScrollBarProps = props

	const [scroll, setScroll] = useState(0)

	useEffect(() => {
		onIndexChange(scroll)
	}, [scroll])

	useEffect(() => {
		const handleWheel = (event) => {
			if (event.deltaY > 0) {
				setScroll((prevScroll) =>
					Math.min(prevScroll + 1, ScrollSections.length - 1)
				)
			} else {
				setScroll((prevScroll) => Math.max(prevScroll - 1, 0))
			}
		}

		window.addEventListener('wheel', handleWheel)

		return () => {
			window.removeEventListener('wheel', handleWheel)
		}
	}, [ScrollSections.length, setScroll])

	return (
		<>
			{ScrollSections.map((_, index) => {
				const isCurrent = index === scroll

				return (
					<div
						key={index}
						onClick={() => setScroll(index)}
						className={`ScrollChip ${isCurrent && 'ScrollChipHighlight'}`}
					>
						<h2>{texts[index]}</h2>
					</div>
				)
			})}
		</>
	)
}
