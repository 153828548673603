import { createContext, useContext, useEffect, useRef, useState } from 'react'

const ModalContext = createContext(undefined)

export const useModal = () => useContext(ModalContext)

export function ModalManager({ children }) {
	const [modal, setModal] = useState(null)
	const modalRef = useRef(null)

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setModal(null)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('touchstart', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}
	}, [modal])

	return (
		<ModalContext.Provider value={{ modal, setModal }}>
			<div>
				{modal && (
					<div className="ModalWrapper">
						<div className="ModalParent" ref={modalRef}>
							{modal}
						</div>
					</div>
				)}
				<div>{children}</div>
			</div>
		</ModalContext.Provider>
	)
}
