import { bulletPoint } from '../constants'
import * as React from 'react'
import { Hyperlink } from './HyperLink'

export function LinkList(props) {
	const { links } = props

	if (!links) return null

	return (
		<div style={{ display: 'flex', gap: '1vh' }}>
			{links.map((e, index) => (
				<>
					<Hyperlink href={e.url}>
						<h2>{e.name}</h2>
					</Hyperlink>
					{/*genius or insane? someone tell me please*/}
					<h2>
						{index != (0 | (links.length - 1)) ? bulletPoint : ''}
					</h2>
				</>
			))}
		</div>
	)
}
