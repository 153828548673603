import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { App } from './App'
import { Woke } from './pages/WokePfp/Woke'

//just memes, i will probably use this at some point
const subRoutes = {
	rupert: () => <img src={'https://samwich.dev/assets/rupert.jpg'} />,
	appl: () => <img src={'https://samwich.dev/assets/appl.png'} />,
	cat: () => (
		<img
			src={'https://cataas.com/cat'}
			style={{
				width: '50vh',
				height: '50vh',
				objectFit: 'cover',
			}}
		/>
	),
	woke: () => <Woke />,
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				{Object.entries(subRoutes).map(([key, Component]) => (
					<Route key={key} path={`/${key}`} element={<Component />} />
				))}
				<Route path="/" element={<App />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
)
