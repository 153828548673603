import { useEffect, useState } from 'react'
import './App.css'
import { ScrollBar } from './components/ScrollBar'
import { ScrollSections } from './components/ScrollSections'
import { isMobileUserAgent } from './utils/isMobileUserAgent'
import { getThemes, ThemeManager } from './components/ThemeManager'
import { ModalManager } from './components/ModalManager'

const texts = ['Intro', 'Links', 'Blog', 'Credits']

export function App() {
	const [scrollElement, setScrollElement] = useState(ScrollSections[0])

	const themes = Object.keys(getThemes())

	const [theme, setTheme] = useState(
		Number(localStorage.getItem('theme')) ?? 0
	)

	useEffect(() => {
		localStorage.setItem('theme', String(theme))
	}, [theme])

	async function scrollAnimation(index): Promise<any> {
		setScrollElement(ScrollSections[index])
	}

	if (window.innerWidth <= 800) {
		return <h1>No small screen support yet (i am very smart)</h1>
	}

	return (
		<ThemeManager theme={themes[theme]}>
			<div
				className="MainApp"
				style={{
					userSelect: 'none',
				}}
			>
				<ModalManager>
					<div className="Content">
						<div className="ModalAndScrollBar">
							<div className="IndicatorBars">
								<ScrollBar
									ScrollSections={ScrollSections}
									onIndexChange={(e) => scrollAnimation(e)}
									texts={texts}
								/>
							</div>
							<div className="CenterModal">
								{scrollElement ?? null}
							</div>
						</div>
						<h4 className="ScrollHint">
							Hint:{' '}
							{isMobileUserAgent() ? 'tap the buttons' : 'scroll'}{' '}
							:)
						</h4>
					</div>
					<div
						className="ThemeChanger"
						onClick={() => setTheme((e) => (e + 1) % themes.length)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 576 512"
						>
							<path
								fill="currentColor"
								d="M339.3 367.1c27.3-3.9 51.9-19.4 67.2-42.9L568.2 74.1c12.6-19.5 9.4-45.3-7.6-61.2s-42.9-17.3-61.5-3.3L262.4 187.2c-24 18-38.2 46.1-38.4 76.1zm-19.6 25.4l-116-104.4C143.9 290.3 96 339.6 96 400c0 3.9.2 7.8.6 11.6c1.8 17.5-10.2 36.4-27.8 36.4H64c-17.7 0-32 14.3-32 32s14.3 32 32 32h144c61.9 0 112-50.1 112-112c0-2.5-.1-5-.2-7.5z"
							/>
						</svg>
					</div>
				</ModalManager>
			</div>
		</ThemeManager>
	)
}
